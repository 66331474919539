// src/store/companiesList/companiesList.slice.js
// This file uses ES6 / ESM syntax

import { createSlice } from "@reduxjs/toolkit";

let temp = null;
if (typeof window !== "undefined") {
  temp = sessionStorage.getItem("requestParams");
}

const sessionStore = temp
  ? JSON.parse(sessionStorage.getItem("requestParams"))
  : null;

const initialState = {
  filtersList: null,
  currentCompaniesList: null,
  requestParams: sessionStore || {
    page: 1,
    limit: 25,
    direction: "DESC",
    orderBy: "updatedAt",
    arrayFilters: {},
  },
  pages: null,
  startNumberCompanyRecord: null,
  endNumberCompanyRecord: null,
  countRecords: null,
  isLoading: true,
  currentFiltersPanel: "Overview",
  showHideFields: {},
  groupBy: {},
  checkedList: [],
  coloredColumns: null,
  companiesListByView: [],
  companiesListByViewParams: null,
  isList: true,
  visibleColumns: null,
};

const companiesListSlice = createSlice({
  name: "companiesList",
  initialState,
  reducers: {
    setCLColoredColumns: (state, { payload }) => {
      state.coloredColumns = payload;
    },
    setCLCurrentFiltersPanel: (state, { payload }) => {
      state.currentFiltersPanel = payload;
    },
    setCLFiltersList: (state, { payload }) => {
      state.filtersList = payload;
    },
    setCLIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setCLCurrentCompaniesList: (state, { payload }) => {
      state.currentCompaniesList = payload;
    },
    setCLCurrentPage: (state, { payload }) => {
      state.requestParams = { ...state?.requestParams, page: payload };
    },
    setCLCurrentLimit: (state, { payload }) => {
      state.requestParams.limit = payload;
    },
    setCLPages: (state, { payload }) => {
      state.pages = payload;
    },
    setCLCurrentDirection: (state, { payload }) => {
      state.requestParams.direction = payload;
    },
    setCLCurrentOrderBy: (state, { payload }) => {
      state.requestParams.orderBy = payload;
    },
    setCLCurrentText: (state, { payload }) => {
      state.requestParams.text = payload;
    },
    setCLCurrentArrayFilters: (state, { payload }) => {
      state.requestParams.arrayFilters = payload;
    },
    resetCLCurrentArrayFilters: (state, { payload }) => {
      state.requestParams = { ...state.requestParams, arrayFilters: {} };
    },
    resetCLRequestParams: (state) => {
      state.requestParams = initialState.requestParams;
    },
    setCLStartNumberCompanyRecord: (state, { payload }) => {
      state.startNumberCompanyRecord = payload;
    },
    setCLEndNumberCompanyRecord: (state, { payload }) => {
      state.endNumberCompanyRecord = payload;
    },
    setCLCountRecords: (state, { payload }) => {
      state.countRecords = payload;
    },
    setCLShowHideFieldsStart: (state, { payload }) => {
      const temp = payload.reduce((acc, { id }) => {
        acc[id] = true;
        return acc;
      }, {});
      state.showHideFields = { ...temp };
    },
    setCLShowHideFields: (state, { payload }) => {
      state.showHideFields = {
        ...state.showHideFields,
        [payload]: !state.showHideFields?.[payload],
      };
    },
    setCLShowHideFieldsShowAll: (state) => {
      state.showHideFields = {
        ...Object.keys(state.showHideFields)?.reduce((acc, el) => {
          acc[el] = true;
          return acc;
        }, {}),
      };
    },
    setCLShowHideFieldsClearAll: (state) => {
      state.showHideFields = {
        ...Object.keys(state.showHideFields)?.reduce((acc, el) => {
          acc[el] = false;
          return acc;
        }, {}),
      };
    },
    setCLGroupByField: (state, { payload }) => {
      state.groupBy = { ...state.groupBy, field: payload };
    },
    setCLGroupByValue: (state, { payload }) => {
      state.groupBy = { ...state.groupBy, value: payload };
    },
    setCLGroupByDirect: (state, { payload }) => {
      state.groupBy = { ...state.groupBy, direct: payload };
    },
    setCLisAddonText: (state, { payload }) => {
      state.groupBy = { ...state.groupBy, isAddonText: payload };
    },
    addClCheckedList: (state, { payload }) => {
      state.checkedList = [...state.checkedList, payload];
    },
    addClAllCheckedList: (state, { payload }) => {
      state.checkedList = [...state.currentCompaniesList];
    },
    removeClCheckedList: (state, { payload }) => {
      state.checkedList = state.checkedList.filter(
        (el) => el?.id !== payload?.id
      );
    },
    removeClAllCheckedList: (state, { payload }) => {
      state.checkedList = [];
    },
    resetCLGroupBy: (state) => {
      state.groupBy = {};
    },
    resetCLCurrentCompaniesList: (state) => {
      state.currentCompaniesList = null;
    },
    setCompaniesListByView: (state, { payload }) => {
      state.companiesListByView = payload;
    },
    setCompaniesListByViewParams: (state, { payload }) => {
      state.companiesListByViewParams = payload;
    },
    setCompaniesListByViewLimit: (state, { payload }) => {
      state.companiesListByViewParams = payload;
    },

    clearCompaniesListByView: (state) => {
      state.companiesListByView = [];
    },
    setIsList: (state, { payload }) => {
      state.isList = payload;
    },
    setVisibleColumns: (state, { payload }) => {
      state.visibleColumns = payload;
    },
  },
});

export const companiesListAction = companiesListSlice.actions;
export default companiesListSlice;
