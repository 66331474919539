// src/store/views/views.slice.js
// This file uses ES6 / ESM syntax

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  views: [],
  currentView: {
    id: "all companies",
    title: "All Companies",
  },
  viewRequestParams: {
    page: 1,
    limit: 25,
    direction: "DESC",
    orderBy: "updatedAt",
    arrayFilters: {},
  },
  showHideViewFields: {},
  visibleViewColumns: null,
};

const viewsSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    setAllViews: (state, { payload }) => {
      state.views = payload;
    },
    setCurrentView: (state, { payload }) => {
      state.currentView = payload;
    },
    setInitView: (state) => {
      state.currentView = initialState.currentView;
    },

    setViewRequestParams: (state, { payload }) => {
      state.viewRequestParams = { ...state?.viewRequestParams, ...payload };
    },
    resetViewRequestParams: (state) => {
      state.viewRequestParams = initialState.viewRequestParams;
    },
    resetViewRequestParamsFilters: (state) => {
      state.viewRequestParams = {
        ...state.viewRequestParams,
        arrayFilters: {},
      };
    },
    setViewCLShowHideFieldsStart: (state, { payload }) => {
      const temp = payload.reduce((acc, { id }) => {
        acc[id] = true;
        return acc;
      }, {});
      state.showHideViewFields = { ...temp };
    },
    setViewCLShowHideFields: (state, { payload }) => {
      state.showHideViewFields = {
        ...state.showHideViewFields,
        [payload]: !state.showHideViewFields?.[payload],
      };
    },
    setViewCLShowHideFieldsShowAll: (state) => {
      state.showHideViewFields = {
        ...Object.keys(state.showHideViewFields)?.reduce((acc, el) => {
          acc[el] = true;
          return acc;
        }, {}),
      };
    },
    setViewCLShowHideFieldsClearAll: (state) => {
      state.showHideViewFields = {
        ...Object.keys(state.showHideViewFields)?.reduce((acc, el) => {
          acc[el] = false;
          return acc;
        }, {}),
      };
    },
    setVisibleViewColumns: (state, { payload }) => {
      state.visibleViewColumns = payload;
    },
  },
});

export const viewsActions = viewsSlice.actions;

export default viewsSlice;
